import classNames from 'classnames';
import { AssetPaths } from 'common/constants/assets';
import { NavigationRoutes } from 'common/routes';
import Text from 'components/elements/text';
import { AppFooter } from 'components/widgets/footer';
import { AppLayout } from 'components/widgets/layout';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import Image from 'next/image';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

export default function FourOFourScreen() {
  const { t } = useTranslation();
  const { replace } = useKurosimNavigation();

  return (
    <AppLayout
      Footer={
        <AppFooter
          mainButtonProps={{
            type: 'main',
            fullWidth: true,
            onClick: () => replace(NavigationRoutes.MySim),
            children: t('common:back_to_extra', { extra: t('home:my_sim') }),
          }}
        />
      }
    >
      <div
        style={{ minHeight: 'calc(100svh - 100px)' }}
        className={classNames(
          structuralStyles.padding({ horizontal: 16 }),
          structuralStyles.flexbox({
            direction: 'column',
            justify: 'center',
            fill: true,
            gap: 24,
          }),
        )}
      >
        <Image
          alt="404"
          width={233}
          height={233}
          src={AssetPaths.PageNotFound}
        />
        <div
          className={structuralStyles.flexbox({
            direction: 'column',
            align: 'stretch',
            gap: 8,
          })}
        >
          <Text ta="center" textVariant="body1Semibold">
            Oops!
          </Text>
          <Text
            ta="center"
            textVariant="body2Regular"
            textColor="foregroundTertiary"
          >
            {t('error:four_o_four_empty_desc')}
          </Text>
        </div>
      </div>
    </AppLayout>
  );
}
